import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Map as MAPBOX, MapRef, MapboxEvent, NavigationControl, Popup, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import LegendPanel from './LegendPanel';
import NetworkLayer from './NetworkLayer';
import { INetworkData } from 'interfaces/networkInterface';
import NetworkPopup from './NetworkPopup';
import hexToRGBA from '@utils/hexToRGBA';
import { TWidthType } from '@hooks/useWidthType';

type TProps = {
  widthType: TWidthType;
};

const NetworkMap = ({ widthType }: TProps) => {
  const mapRef = useRef<MapRef>(null);
  const [selectedPosition, setSelectedPosition] = useState<INetworkData>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [mapViewState, setMapViewState] = useState<ViewState>({
    latitude: 35.91401338705238,
    longitude: 128.84336471557617,
    zoom: widthType === 'mobile' ? 5.5 : 6,
    bearing: 0,
    pitch: 0,
    padding: { bottom: 0, left: 0, right: 0, top: 0 },
  });

  const onPopupClose = useCallback(() => {
    setSelectedPosition(undefined);
    setShowPopup(false);
  }, []);

  const onMapLoadHandler = (e: MapboxEvent) => {
    setIsLoaded(true);
  };

  const onMapMoveHandler = (e: ViewStateChangeEvent) => {
    setMapViewState(e.viewState);
  };

  useEffect(() => {
    if (!mapRef.current || !widthType) return;

    if (selectedPosition) {
      widthType === 'mobile' &&
        mapRef.current?.flyTo({ center: selectedPosition.coordinates as [number, number], duration: 0, offset: [-170, -125] });
      setShowPopup(true);
    }
  }, [selectedPosition, widthType]);

  return (
    <Container>
      <MAPBOX
        {...mapViewState}
        ref={mapRef}
        mapStyle={process.env.REACT_APP_DARK_STYLE}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
        onLoad={onMapLoadHandler}
        onMove={onMapMoveHandler}
        renderWorldCopies={false}
        projection={{ name: 'mercator' }}
        maxBounds={[116.10206668370057, 29.86610147826427, 143.73154799335805, 44.52697846134734]}
      >
        {isLoaded && <LegendPanel />}
        {isLoaded && <NetworkLayer onClick={setSelectedPosition} />}
        {isLoaded && showPopup && selectedPosition && <NetworkPopup data={selectedPosition} onClose={onPopupClose} />}
        <NavigationControl showCompass={false} position={'bottom-right'} />
      </MAPBOX>
    </Container>
  );
};

export default NetworkMap;

const Container = styled.div`
  div.mapboxgl-map {
    div.mapboxgl-control-container {
      div.mapboxgl-ctrl-bottom-right div.mapboxgl-ctrl.mapboxgl-ctrl-group {
        background: none;
        box-shadow: none;
        margin: 0px 20px 10px 0px;

        & > button {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.newColors.grey1};
          box-shadow:
            1px 1px 1px 0px ${({ theme }) => hexToRGBA(theme.newColors.white, 0.3)} inset,
            0px 3px 5px 0px ${({ theme }) => hexToRGBA(theme.newColors.black, 0.15)};

          &[aria-disabled='false']:hover {
            background-color: ${({ theme }) => theme.newColors.primary};
          }

          &.mapboxgl-ctrl-zoom-in {
            margin-bottom: 10px;
            span {
              background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikdyb3VwIDczMjciPgo8cGF0aCBpZD0iTGluZSA1MzEiIGQ9Ik0xIDguMDM4NTdIMTUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggaWQ9IkxpbmUgNTMyIiBkPSJNNy45NjE0MyAxTDcuOTYxNDMgMTUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9nPgo8L3N2Zz4K);
            }
          }
          &.mapboxgl-ctrl-zoom-out {
            span {
              background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDE2IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFIMTUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
            }
          }
        }

        & > button + button {
          border-top: none;
        }
      }
    }
  }

  ${({ theme }) =>
    theme.media.tablet(`
      div.mapboxgl-ctrl.mapboxgl-ctrl-group {
        & > button {
          display: none;
        }
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      div.mapboxgl-ctrl.mapboxgl-ctrl-group {
        & > button {
          display: none;
        }
      }

      .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
        bottom: 79px;
      }
  `)}

  @media (max-width: 543px) {
    .mapboxgl-ctrl-bottom-left,
    .mapboxgl-ctrl-bottom-right {
      bottom: 108px;
    }
  }

  @media (max-width: 332px) {
    .mapboxgl-ctrl-bottom-left,
    .mapboxgl-ctrl-bottom-right {
      bottom: 130px;
    }
  }
`;
