import s from '@utils/i18nextScan';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';

export const DataTense = {
  present: { title: s('tenseSelector.present'), disabled: false },
  future: { title: s('tenseSelector.future'), disabled: true },
};

export const AbsorptionData = {
  nep: {
    key: 'nep',
    title: s('absorptionData-nep-title'),
  },
} satisfies { [key: string]: { key: TCarbonSourceDataType; title: string } };

export const EmissionData = {
  ei: {
    key: 'ei',
    title: s('absorptionData-ei-title'),
  },
  mic: {
    key: 'mic',
    title: s('absorptionData-mic-title'),
  },
  f: {
    key: 'f',
    title: s('absorptionData-f-title'),
  },
  rt: {
    key: 'rt',
    title: s('absorptionData-rt-title'),
  },
  os: {
    key: 'os',
    title: s('absorptionData-os-title'),
  },
} satisfies { [key: string]: { key: TCarbonSourceDataType; title: string } };

export const carbonCategoryKey = {
  absorption: Object.keys(AbsorptionData),
  emission: Object.keys(EmissionData),
};

export const CarbonLegend = {
  mixed: {
    title: s('carbonLegend.mixed'),
    color: ['#FFF3E0', '#FFE0B2', '#FFCC80', '#FFB74D', '#FFA726', '#FF9800', '#FB8C00', '#F57C00', '#EF6C00', '#E65100'],
  },
  absorption: {
    title: s('carbonLegend.absorption'),
    color: ['#E0F2F1', '#B2DFDB', '#80CBC4', '#4DB6AC', '#26A69A', '#009688', '#00897B', '#00796B', '#00695C', '#004D40'],
  },
  emission: {
    title: s('carbonLegend.emission'),
    color: ['#FFEBEE', '#FFCDD2', '#EF9A9A', '#E57373', '#EF5350', '#F44336', '#E53935', '#D32F2F', '#C62828', '#B71C1C'],
  },
};
