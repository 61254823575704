import hexToRGBA from '@utils/hexToRGBA';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface IMemberInfo {
  image: string;
  info: string[];
}

type Props = {
  member: IMemberInfo;
};

const MemberItem = ({ member }: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Image name={member.image} />
      <InfoWrapper>
        <Text>{t(member.info[0])}</Text>
        <Text className="name">
          <span>{t(member.info[1])}</span>
        </Text>
      </InfoWrapper>
      <Description>{t(member.info[2])}</Description>
    </Container>
  );
};

export default MemberItem;

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(135px, 135px) 1fr;
  grid-template-areas:
    'profile name'
    'profile desc';

  align-items: center;
  gap: 20px;

  padding: 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};

  ${({ theme }) =>
    theme.media.tablet(`
      grid-template-columns: minmax(110px, 110px) 1fr;
      grid-template-rows: minmax(150px, 150px) 1fr;
      grid-template-areas:
        'profile name'
        'desc desc';
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      display: flex;
      flex-direction: column;
      align-items: baseline;
  `)}
`;

const InfoWrapper = styled.div`
  grid-area: name;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;

  height: 100%;

  ${({ theme }) =>
    theme.media.tablet(`
      height: inherit;
      justify-content: normal;
      align-items: normal;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
  `)}
`;

const Image = styled.div<{ name: string }>`
  border-radius: 5px;
  grid-area: profile;
  height: 189px;
  flex-shrink: 0;

  background-image: url(${(props) => `/images/profile/${props.name}`});
  background-size: cover;
  background-repeat: no-repeat;

  ${({ theme }) =>
    theme.media.tablet(`
      min-width: 110px;
      height: 150px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      display: none;
  `)}
`;

const Text = styled.div`
  color: ${({ theme }) => theme.newColors.white};

  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;

  &.name {
    display: flex;
    gap: 20px;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      font-size: 18px;
      line-height: 28px;

      &.name {
        flex-direction: column;
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 18px;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.36px;

      &.name {
        flex-direction: column;
        >span{
          white-space : pre-line;
        }
      }
  `)}
`;

const Description = styled.div`
  grid-area: desc;

  height: 100%;

  font-size: 16px;
  font-variation-settings: 'wght' 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.32px;

  ${({ theme }) =>
    theme.media.tablet(`
  `)}
`;
