import { useState, useCallback, useEffect } from 'react';

export type TWidthType = 'mobile' | 'tablet' | 'desktop';

const useWidthType = () => {
  const [widthType, setWidthType] = useState<TWidthType>();

  const resizeWindow = useCallback(() => {
    if (window.innerWidth < 768) {
      setWidthType('mobile');
    } else if (window.innerWidth >= 768 && window.innerWidth < 1250) {
      setWidthType('tablet');
    } else if (window.innerWidth >= 1250) {
      setWidthType('desktop');
    }
  }, []);

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, [resizeWindow]);

  return widthType;
};

export default useWidthType;
