import ChartGroupItem from '@components/dashboard/chart/ChartGroupItem';
import ChartItem from '@components/dashboard/chart/ChartItem';
import { ChartData } from '@static/chartData';
import { CarbonLegend } from '@static/mapData';
import { getCarbonLegendType } from '@utils/carbonData';
import { getCarbonSourceTitle } from '@utils/getCarbonSourceTitle';
import { IChartDataType } from 'interfaces/chartInterface';
import { useCarbonChartQuery } from 'queries/useCarbonChartQuery';
import { useCallback, useEffect, useState } from 'react';
import useDashboardStore, { TCarbonCompare } from 'stores/dashboardStore';
import styled from 'styled-components';
import BarChart from '../chart/BarChart';
import { Chart } from 'chart.js';
import theme from '@styles/theme';
import LineChart from '../chart/LineChart';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type Props = {
  param: TCarbonCompare;
};

const CarbonCompareResult = ({ param }: Props) => {
  const { carbonCompare } = useDashboardStore();
  const { data } = useCarbonChartQuery(param);
  const [isShowStatus, setIsShowStatus] = useState<{ [key: string]: boolean }>({});
  const [lineData, setLineData] = useState<{ labels: string[]; datasets: IChartDataType[] }>();
  const [barData, setBarData] = useState<{ labels: string[]; datasets: IChartDataType[] }>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!carbonCompare) return;
    if (!data) return;
    const result = {};
    data.map((_, idx) => {
      result[idx] = true;
    });
    setIsShowStatus(result);

    const lineLabel: string[] = [];
    const lineResult: IChartDataType[] = [];

    const barLabel: string[] = getCarbonSourceTitle(carbonCompare.dataType);
    const barResult: IChartDataType[] = [];

    data[0].monthly.map((sub) => lineLabel.push(dayjs(sub.yearMonth).format('YYYY.MM')));
    data.map((item, idx) => {
      lineResult.push({
        id: idx.toString(),
        label: `${t('common.group')} ${idx + 1}`,
        data: Object.values(item.monthly.map((sub) => sub.total)),
        hidden: false,
        borderColor: ChartData[idx].borderColor,
        backgroundColor: ChartData[idx].borderColor,
      });

      barResult.push({
        id: idx.toString(),
        label: `${t('common.group')} ${idx + 1}`,
        data: Object.values(item.dataType),
        hidden: false,
        borderColor: ChartData[idx].borderColor,
        backgroundColor: ChartData[idx].borderColor,
      });
    });

    setLineData({ labels: lineLabel, datasets: lineResult });
    setBarData({ labels: barLabel, datasets: barResult });
  }, [data]);

  useEffect(() => {
    if (!lineData || !barData) return;
    setLineData({
      ...lineData,
      datasets: lineData.datasets.map((item) => {
        return { ...item, hidden: !isShowStatus[item.id] };
      }),
    });
    setBarData({
      ...barData,
      datasets: barData.datasets.map((item) => {
        return { ...item, hidden: !isShowStatus[item.id] };
      }),
    });
  }, [isShowStatus]);

  useEffect(() => {
    Chart.defaults.color = theme.newColors.white;
    Chart.defaults.borderColor = theme.newColors.white;

    // Chart.defaults.font.size = 15;
  }, []);

  const handleShowStatusChange = useCallback((id: string, status: boolean) => {
    setIsShowStatus((prev) => {
      const temp = { ...prev };
      temp[id] = status;
      return temp;
    });
  }, []);

  return (
    <>
      {data && (
        <Container>
          <GroupList>
            {Object.keys(isShowStatus).length > 0 &&
              data.map((group, idx) => (
                <li key={idx}>
                  <ChartGroupItem idx={idx} data={group} onShowStatusChange={handleShowStatusChange} />
                </li>
              ))}
          </GroupList>
          {carbonCompare && Object.keys(isShowStatus).length > 0 && (
            <>
              <ChartItem
                title={t('dashboard.carbonCompareResult.status', {
                  type: t(CarbonLegend[getCarbonLegendType(carbonCompare.dataType)].title),
                })}
                groupIds={isShowStatus}
              >
                {lineData && <LineChart data={lineData} />}
              </ChartItem>
              <ChartItem
                title={t('dashboard.carbonCompareResult.typeStatus', {
                  type: t(CarbonLegend[getCarbonLegendType(carbonCompare.dataType)].title),
                })}
                groupIds={isShowStatus}
              >
                {barData && <BarChart data={barData} />}
              </ChartItem>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default CarbonCompareResult;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const GroupList = styled.div`
  display: flex;
  gap: 24px;

  li {
    flex: 1 1 100%;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      flex-wrap: wrap;

      li {
        flex: 1 1 45%;
        max-width: calc(50% - 12px);
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      flex-wrap: wrap;

      li {
        flex: 1 1 100%;
      }
  `)}
`;
