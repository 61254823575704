import BasicModal from '@components/common/BasicModal';
import React, { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ReactComponent as CloseIcon } from '@assets/icons/close_icon_19.svg';
import styled from 'styled-components';
import hexToRGBA from '@utils/hexToRGBA';
import CustomCheckbox from '@components/common/CustomCheckbox';
import { useTranslation } from 'react-i18next';

const getExpiredDate = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

const CautionModal = () => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [_, setAppCookies] = useCookies();
  const { t } = useTranslation();

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!checkboxRef.current) return;
    if (checkboxRef.current.checked) {
      const expires = getExpiredDate(1);
      setAppCookies('carbonbudget', true, { path: '/', expires });
    }
    setIsOpen(false);
  };

  return (
    <BasicModal portalID="#portal" isOpen={isOpen} onRequestClose={() => setIsOpen(false)} isCloseWhenAroundClick={false}>
      <Container>
        <Header>
          <Title>{t('common.notice')}</Title>
          <CloseIcon onClick={() => setIsOpen(false)} />
        </Header>
        <Content>
          <Text>{t('cautionModal.description')}</Text>
          <CheckBox>
            <CustomCheckbox id="chk_today_cookie" label={t('cautionMoal.hideForToday')} ref={checkboxRef} />
          </CheckBox>
          <Button onClick={handleCheckboxClick}>{t('common.confirm')}</Button>
        </Content>
      </Container>
    </BasicModal>
  );
};

export default CautionModal;

const Container = styled.div`
  width: 100%;
  max-width: 340px;
  background-color: ${({ theme }) => theme.newColors.dark3};
  box-shadow: 1px 3px 10px 0px ${({ theme }) => hexToRGBA(theme.newColors.black, 0.15)};
  border-radius: 8px;

  ${({ theme }) =>
    theme.media.mobile(`
      width: calc(100% - 50px);
  `)}
`;

const Header = styled.div`
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 50px;
  border-bottom: 1px solid ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.3)};

  svg {
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.newColors.white};
  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px; /* 122.222% */
`;

const Content = styled.div`
  padding: 25px 20px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.newColors.white};
  font-size: 16px;
  font-variation-settings: 'wght' 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.32px;
  text-align: center;

  width: 250px;
  word-break: keep-all;

  ${({ theme }) =>
    theme.media.mobile(`
      max-width: fit-content;
  `)}
`;

const CheckBox = styled.div`
  margin: 25px 0px 20px 0px;

  label[for='chk_today_cookie'] {
    color: ${({ theme }) => theme.newColors.grey1};
    font-size: 16px;
    font-variation-settings: 'wght' 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  border-radius: 20px;
  background-color: ${({ theme }) => theme.newColors.grey1};
  box-shadow:
    0px 3px 5px 0px rgba(0, 0, 0, 0.15),
    1px 1px 2px 0px rgba(244, 245, 249, 0.4) inset;

  &:hover {
    background-color: ${({ theme }) => theme.newColors.primary};
  }
`;
