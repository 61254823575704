import CarbonCompareResult from '@components/dashboard/carbonCompare/CarbonCompareResult';
import CarbonCompareSearch from '@components/dashboard/carbonCompare/CarbonCompareSearch';
import { useTranslation } from 'react-i18next';
import useDashboardStore from 'stores/dashboardStore';
import styled from 'styled-components';

const CarbonCompare = () => {
  const { carbonCompare } = useDashboardStore();
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('dashboard.carboncompare-title')}</Title>
      <Description>
        <span>{t('dashboard.carboncompare-description01')}</span>
        <span className="gray">{t('dashboard.carboncompare-description02')}</span>
      </Description>
      <CarbonCompareSearch />
      {carbonCompare && <CarbonCompareResult param={carbonCompare} />}
    </Container>
  );
};

export default CarbonCompare;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 25px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

const Description = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.36px;

  span.gray {
    color: ${({ theme }) => theme.newColors.grey1};
  }

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.32px;
  `)}
`;
