import CustomCheckbox from '@components/common/CustomCheckbox';
import { AbsorptionData, EmissionData } from '@static/mapData';
import { ChangeEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type TProps = {
  inputName: string;
  backgroundColor?: string;
};

const checkList = Object.keys(AbsorptionData)
  .map((key) => key)
  .concat(Object.keys(EmissionData).map((key) => key));

const CarbonCategoryBody = ({ inputName, backgroundColor }: TProps) => {
  const { register, setValue } = useFormContext();
  const checkedCount: number = useWatch({ name: inputName }).length;
  const maxCheckCount = checkList.length;
  const { t } = useTranslation();

  const handleTotalCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setValue(inputName, checked ? checkList : [], { shouldValidate: true });
  };

  return (
    <Container $backgroundColor={backgroundColor}>
      <CustomCheckbox
        id="carbon-category-total"
        label={t('common.all')}
        onChange={handleTotalCheck}
        checked={checkedCount === maxCheckCount}
      />
      <Hr />
      <Title>{t('common.emission')}</Title>
      {Object.keys(EmissionData).map((key) => {
        const data = EmissionData[key];
        return (
          <CustomCheckbox
            key={key}
            {...register(inputName, { required: true })}
            id={`carbon-category-${key}`}
            value={key}
            label={t(data.title)}
          />
        );
      })}
      <Hr />
      <Title>{t('common.absorption')}</Title>
      {Object.keys(AbsorptionData).map((key) => {
        const data = AbsorptionData[key];
        return (
          <CustomCheckbox
            key={key}
            {...register(inputName, { required: true })}
            id={`carbon-category-${key}`}
            value={key}
            label={t(data.title)}
          />
        );
      })}
    </Container>
  );
};

export default CarbonCategoryBody;

const Container = styled.div<{ $backgroundColor?: string }>`
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.newColors.dark1};
  border-radius: 0 0 5px 5px;
  padding: 10px;

  ${({ theme }) =>
    theme.media.mobile(`
      position: relative;
      top: 0px;
  `)}
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${({ theme }) => theme.newColors.dark3};
`;

const Title = styled.span`
  font-variation-settings: 'wght' 600;
`;
