import CarbonCompare from '@components/dashboard/carbonCompare/CarbonCompare';
import CarbonRanking from '@components/dashboard/carbonRanking/CarbonRanking';
import styled from 'styled-components';

const Dashboard = () => {
  return (
    <Container>
      <ContentsContainer>
        <CarbonRanking />
        <CarbonCompare />
      </ContentsContainer>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 100px 30px 140px 30px;

  ${({ theme }) =>
    theme.media.tablet(`
      padding-top: 50px;
      padding-bottom: 100px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      padding-top: 30px;
      padding-bottom: 50px;
  `)}
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  width: 100%;
  max-width: 1248px;

  ${({ theme }) =>
    theme.media.tablet(`
      gap: 80px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      gap: 75px;
  `)}
`;
