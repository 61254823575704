import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as EyeShowIcon } from '@assets/icons/panel/eye_show_icon.svg';
import { ReactComponent as EyeNoShowIcon } from '@assets/icons/panel/eye_no_show_icon.svg';
import useDashboardStore from 'stores/dashboardStore';
import hexToRGBA from '@utils/hexToRGBA';
import { ChartData } from '@static/chartData';
import { getLocationNameList } from '@utils/getLocationName';
import { TGetCarbonChartDataResponse } from 'interfaces/dashboardInterface';
import { useTranslation } from 'react-i18next';
import i18n from '@utils/i18n';

type Props = {
  idx: number;
  data: TGetCarbonChartDataResponse;
  onShowStatusChange: (id: string, status: boolean) => void;
};

const ChartGroupItem = ({ idx, data, onShowStatusChange }: Props) => {
  const { carbonCompare } = useDashboardStore();
  const [isShow, setIsShow] = useState(true);
  const { t } = useTranslation();
  const activeLocale = i18n.language === 'ko' ? 'ko' : 'en';

  const handleEyeButtonClick = () => {
    setIsShow((prev) => !prev);
  };

  useEffect(() => {
    onShowStatusChange(idx.toString(), isShow);
  }, [isShow]);

  return (
    <Container>
      <Title $groupIndex={idx + 1}>
        <div className="left">
          <span className="group">
            {t('common.group')} {idx + 1}
          </span>
        </div>
        <button onClick={handleEyeButtonClick}>{isShow ? <EyeShowIcon /> : <EyeNoShowIcon />}</button>
      </Title>
      {carbonCompare && (
        <Description>
          <span className="name">{getLocationNameList(carbonCompare.location[idx], true, activeLocale)}</span>
          <CarbonWrapper>
            <span>{data.total.toLocaleString('ko-KR')}</span>
            <span className="gray">tonCO2eq</span>
          </CarbonWrapper>
        </Description>
      )}
    </Container>
  );
};

export default ChartGroupItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};
`;

const Title = styled.div<{ $groupIndex: number }>`
  display: flex;
  justify-content: space-between;

  div.left {
    font-size: 18px;
    font-variation-settings: 'wght' 600;
    line-height: 22px;

    span.group {
      color: ${({ $groupIndex }) => ChartData[$groupIndex - 1].borderColor};
    }
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  span.name {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
`;

const CarbonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  font-size: 25px;
  font-weight: 800;
  letter-spacing: -0.5px;

  span.gray {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
    color: ${({ theme }) => theme.newColors.grey2};
  }
`;
