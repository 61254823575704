import MapCommonButton from '@components/common/MapCommonButton';
import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DataResultIcon } from '@assets/map/data_setting.svg';
import useMapStore from 'stores/mapStore';
import Legend from '@components/map/resultPanel/Legend';
import hexToRGBA from '@utils/hexToRGBA';
import { ReactComponent as CloseIcon } from '@assets/icons/close_icon_15.svg';
import ResultCarbonItem from '@components/map/resultPanel/ResultCarbonItem';
import { getLocationNameByObject } from '@utils/getLocationName';
import { TGetTotalCarbonDataResponse } from 'interfaces/mapInterface';
import { TWidthType } from '@hooks/useWidthType';
import MobileLegend from './MobileLegend';
import i18n from '@utils/i18n';
import { useTranslation } from 'react-i18next';

type TProps = {
  data: TGetTotalCarbonDataResponse;
  widthType: TWidthType;
};

const ResultPanel = ({ data, widthType }: TProps) => {
  const { selectDataCondition } = useMapStore();
  const [isPanelOpen, setIsPanelOpen] = useState(widthType !== 'mobile' ? true : false);
  const { t } = useTranslation();
  const activeLocale = i18n.language === 'ko' ? 'ko' : 'en';

  return (
    <>
      <Container>
        {!isPanelOpen && <MapCommonButton icon={<DataResultIcon />} onClick={() => setIsPanelOpen(true)} isHover={true} />}
        {isPanelOpen && selectDataCondition.dataType && (
          <PanelContainer $isOpen={isPanelOpen}>
            <CloseButton onClick={() => setIsPanelOpen(false)}>
              <CloseIcon />
            </CloseButton>
            <Title>{widthType === 'mobile' ? t('resultPanel.administrative') : t('common.legend')}</Title>
            {widthType !== 'mobile' && (
              <>
                <Legend carbonType={selectDataCondition.dataType} />
                <Hr />
              </>
            )}
            <LocationList>
              {getLocationNameByObject(selectDataCondition.location, data, activeLocale).map((item) => {
                return (
                  <li key={item.title}>
                    <ResultCarbonItem location={item.title} carbonAmount={item.value} />
                  </li>
                );
              })}
            </LocationList>
          </PanelContainer>
        )}
      </Container>
      {widthType === 'mobile' && <MobileLegend carbonType={selectDataCondition.dataType} />}
    </>
  );
};

export default ResultPanel;

const Container = styled.div`
  position: absolute;
  left: 10px;
  bottom: 40px;
  z-index: 2;

  ${({ theme }) =>
    theme.media.tablet(`
      bottom: 100px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      bottom: initial;
      left: initial;
      top: 65px;
      right: 10px;
  `)}
`;

const PanelContainer = styled.div<{ $isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 270px;
  padding: 15px;
  border-radius: 5px;

  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark1, 0.8)};
  box-shadow: 2px 5px 10px 0px ${({ theme }) => hexToRGBA(theme.newColors.black, 0.3)};
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  ${({ theme }) =>
    theme.media.mobile(`
      width: 200px;
  `)}
`;

const Title = styled.span`
  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.28px;
  `)}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${({ theme }) => theme.newColors.dark3};
`;

const LocationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
