import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IChartDataType } from 'interfaces/chartInterface';
import styled from 'styled-components';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend);

type Props = {
  data: { labels: string[]; datasets: IChartDataType[] };
};

const LineChart = ({ data }: Props) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        border: { display: false },
        ticks: {
          padding: 10,
          autoSkip: true,
          autoSkipPadding: 50,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        grid: { display: false },
        ticks: {
          crossAlign: 'far' as 'far' | 'center' | 'near' | undefined,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          drag: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x' as 'x' | 'y' | 'xy',
        },
      },
    },
  };

  return (
    <Container>
      <Line options={options} data={data} />
    </Container>
  );
};

export default LineChart;

const Container = styled.div`
  position: relative;
  height: 350px;
  width: 100%;
`;
