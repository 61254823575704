import styled from 'styled-components';

type TProps = {
  location: string;
  carbonAmount: number;
};

const ResultCarbonItem = ({ location, carbonAmount }: TProps) => {
  return (
    <Container>
      <span className="name">{location}</span>
      <CarbonContent>
        <span className="number">{carbonAmount.toLocaleString('ko-KR')}</span>
        <span className="unit">tonCO2eq</span>
      </CarbonContent>
    </Container>
  );
};

export default ResultCarbonItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  span.name {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.32px;
  }

  ${({ theme }) =>
    theme.media.mobile(`
      span.name {
        font-size: 14px;
        font-weight: 600; 
        line-height: 18px;
        letter-spacing: -0.28px;
      }
  `)}
`;

const CarbonContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.number {
    font-size: 25px;
    font-weight: 800;
    letter-spacing: -0.5px;
  }

  span.unit {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
    color: ${({ theme }) => theme.newColors.grey2};
  }

  ${({ theme }) =>
    theme.media.mobile(`
      span.number {
        font-size: 20px;
        line-height: 30px; /* 150% */
        letter-spacing: -0.4px;
      }

      span.unit {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.24px;
      }
  `)}
`;
