import CarbonRankingResult from '@components/dashboard/carbonRanking/CarbonRankingResult';
import CarbonRankingSearchBar from '@components/dashboard/carbonRanking/CarbonRankingSearchBar';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CarbonRanking = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t('dashboard.carbonRanking-title')}</Title>
      <Description>{t('dashboard.carbonRanking-description')}</Description>
      <CarbonRankingSearchBar />
      <CarbonRankingResult />
    </Container>
  );
};

export default CarbonRanking;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 25px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

const Description = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.36px;
`;
