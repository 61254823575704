import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoWhiteIcon } from '@assets/icons/header/logo_white_icon.svg';
import { Trans, useTranslation } from 'react-i18next';

const Main = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <VideoWrapper>
        <Video playsInline loop autoPlay muted>
          <source src={'/video/main_notext.webm'} type="video/webm" />
          <source src={'/video/main.mp4'} type="video/mp4" />
        </Video>
        <Overlay>
          <LogoWhiteIcon width={300} />
          <Text>
            <div>
              <Trans i18nKey={t('main.description')} components={{ span: <span className="bold" /> }} />
            </div>
          </Text>
        </Overlay>
      </VideoWrapper>
    </Container>
  );
};

export default Main;

const Container = styled.div``;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  z-index: 1;

  ${({ theme }) =>
    theme.media.mobile(`
      & > svg {
        width: 152px;
        height: 52px;
      }
  `)}
`;

const Text = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 26px;
  line-height: 38px;
  white-space: pre-line;

  > div {
    margin-inline: 20px;
  }

  span.bold {
    font-variation-settings: 'wght' 700;
  }

  ${({ theme }) =>
    theme.media.mobile(`
      
      font-size: 16px;
      line-height: 28px; /* 175% */
      letter-spacing: -0.32px;
  `)}

  @media screen and (max-width : 450px) {
    white-space: normal;
  }
`;
