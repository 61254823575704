import s from '@utils/i18nextScan';

export const apporachData = {
  main: {
    text: s('approach.data.main-text'),
  },
  first: {
    title: s('approach.data.first-title'),
    text: s('approach.data.first-text'),
  },
  second: {
    title: s('approach.data.second-title'),
    text: s('approach.data.second-text'),
  },
  third: {
    title: s('approach.data.third-title'),
    text: s('approach.data.third-text'),
  },
};

export const categoryData = {
  first: [
    {
      title: s('approach.categoryData.first-activity'),
      data: [
        {
          img: 'activity-1.svg',
          text: s('approach.categoryData.first-activity-text01'),
        },
        {
          img: 'activity-2.svg',
          text: s('approach.categoryData.first-activity-text02'),
        },
        {
          img: 'activity-3.svg',
          text: s('approach.categoryData.first-activity-text03'),
        },
        {
          img: 'activity-4.svg',
          text: s('approach.categoryData.first-activity-text04'),
        },
        {
          img: 'activity-5.svg',
          text: s('approach.categoryData.first-activity-text05'),
        },
        {
          img: 'activity-6.svg',
          text: s('approach.categoryData.first-activity-text06'),
        },
      ],
      gridColumn: 3,
    },
    {
      title: s('approach.categoryData.first-ground'),
      data: [
        {
          img: 'ground-1.svg',
          text: s('approach.categoryData.first-ground-text01'),
        },
        {
          img: 'ground-2.svg',
          text: s('approach.categoryData.first-ground-text02'),
        },

        {
          img: 'ground-4.svg',
          text: s('approach.categoryData.first-ground-text04'),
        },
        {
          img: 'ground-3.svg',
          text: s('approach.categoryData.first-ground-text03'),
        },
      ],
      gridColumn: 3,
    },
    {
      title: s('approach.categoryData.first-air'),
      data: [
        {
          img: 'air-1.svg',
          text: s('approach.categoryData.first-air-text01'),
        },
        {
          img: 'air-2.svg',
          text: s('approach.categoryData.first-air-text02'),
        },
      ],
      gridColumn: 2,
    },
  ],
  second: {
    title: s('approach.categoryData.second-complex'),
    data: [
      {
        img: 'complex-1.svg',
        text: s('approach.categoryData.second-complex-text01'),
      },
      {
        img: 'complex-2.svg',
        text: s('approach.categoryData.second-complex-text02'),
      },
      {
        img: 'complex-3.svg',
        text: s('approach.categoryData.second-complex-text03'),
      },
      {
        img: 'complex-4.svg',
        text: s('approach.categoryData.second-complex-text04'),
      },
      {
        img: 'complex-5.svg',
        text: s('approach.categoryData.second-complex-text05'),
      },
    ],
    gridColumn: 5,
  },
};
