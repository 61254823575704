import { CarbonLegend } from '@static/mapData';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';

export const getCarbonLegendBackground = (type: string, direction: 'horizontal' | 'vertical') => {
  const data: string[] = CarbonLegend[type]['color'];
  const result: string[] = data.map((color, idx) => `${color} ${10 * idx}%`);

  return `linear-gradient(${direction === 'horizontal' ? 'to right' : 'to top'} , ${result.join(', ')});`;
};

export const getCarbonLegendType = (type: TCarbonSourceDataType[]) => {
  if (type.includes('nep')) {
    return type.length === 1 ? 'absorption' : 'mixed';
  } else {
    return 'emission';
  }
};
