import { styled } from 'styled-components';

const Footer = () => {
  return (
    <Container>
      <span>Copyright © 2023 KCP(Korea Carbon Project).All rights reserved.</span>
      <span>kcp@korea-carbon-project.org</span>
    </Container>
  );
};

export default Footer;

const Container = styled.footer`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 20px;
  background-color: ${({ theme }) => theme.newColors.dark1};
  z-index: 3;

  span {
    color: ${({ theme }) => theme.newColors.grey1};
    font-size: 14px;
    font-variation-settings: 'wght' 400;
    line-height: 22px;
    letter-spacing: -0.28px;
  }

  ${({ theme }) =>
    theme.media.mobile(`
      flex-direction: column;
      padding: 11px 20px;
      height: max-content;
      justify-content: space-between;
      align-items: normal;
      gap: 10px;

      span:first-child {
        order: 1;
        font-size: 12px;
        line-height: 22px; /* 183.333% */
        letter-spacing: -0.24px;
      }
  `)}
`;
