import { getCarbonLegendBackground, getCarbonLegendType } from '@utils/carbonData';
import hexToRGBA from '@utils/hexToRGBA';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';
import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
  carbonType: TCarbonSourceDataType[];
};

const MobileLegend = ({ carbonType }: Props) => {
  const type = getCarbonLegendType(carbonType);
  const { t } = useTranslation();

  return (
    <Container className="mobile-legend">
      <Title>{t('common.legend')}</Title>
      <Wrapper>
        <RangeBar $type={type} />
        <Value>
          <Text>Max</Text>
          <Text>Min</Text>
        </Value>
      </Wrapper>
      <Text $isUnit={true}>tonCO2eq</Text>
    </Container>
  );
};

export default MobileLegend;

const Container = styled.div`
  position: absolute;
  bottom: 40px;
  left: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 5px;

  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark1, 0.8)};
  box-shadow: 2px 5px 10px 0px ${({ theme }) => hexToRGBA(theme.newColors.black, 0.3)};
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  transform: translateY(-40px);
`;

const Title = styled.span`
  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.28px;
  `)}
`;

const RangeBar = styled.div<{ $type: string }>`
  width: 15px;
  height: 40px;
  border-radius: 2px;
  background: ${(props) => getCarbonLegendBackground(props.$type, 'vertical')};
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const Value = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Text = styled.div<{ $isUnit?: boolean }>`
  color: ${({ theme }) => theme.newColors.grey2};
  font-size: 14px;
  font-variation-settings: 'wght' 400;
  line-height: 15px;
  letter-spacing: -0.28px;

  ${({ $isUnit }) =>
    $isUnit &&
    css`
      color: ${({ theme }) => theme.newColors.grey1};
      font-size: 12px;
      letter-spacing: -0.24px;
    `}
`;
