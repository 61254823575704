import React from 'react';
import styled from 'styled-components';
import ApproachItem from './ApproachItem';
import Category from './Category';
import { apporachData } from '@static/approachData';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

const Approach = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <TitleContainer>
        <Trans i18nKey={t(apporachData.main.text)} components={{ span: <span /> }} />
      </TitleContainer>
      <ContentsContainer>
        <ApproachItem title={t(apporachData.first.title)} text={t(apporachData.first.text)} />
        <ApproachItem title={t(apporachData.second.title)} text={t(apporachData.second.text)} />
        <ApproachItem title={t(apporachData.third.title)} text={t(apporachData.third.text)}>
          <Category />
        </ApproachItem>
        <Link to={'/map'}>
          <LinkButton>{t('approach.button-moveCarbonBudget')}</LinkButton>
        </Link>
      </ContentsContainer>
    </Container>
  );
};

export default Approach;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1248px;
  padding-bottom: 60px;
  margin-inline: auto;

  ${({ theme }) =>
    theme.media.tablet(`
      padding-inline: 30px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      padding-inline: 20px;
  `)}
`;

const TitleContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.newColors.white};
  word-break: keep-all;

  text-align: center;
  font-size: 35px;
  font-variation-settings: 'wght' 700;
  line-height: 70px; /* 200% */
  letter-spacing: -0.7px;

  margin: 200px 0px 210px 0px;

  span {
    color: ${({ theme }) => theme.newColors.primary};
  }

  ${({ theme }) =>
    theme.media.tablet(`
      margin-block: 150px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 20px;
      line-height: 35px; /* 175% */
      letter-spacing: -0.4px;
      margin-block: 110px;
  `)}
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 100px;
  padding-bottom: 100px;

  ${({ theme }) =>
    theme.media.tablet(`
      gap: 0;

      & > div {
        margin-bottom: 50px;
      }

      & > div:first-child, & > div:nth-child(2) {
        margin-bottom: 100px;
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      gap: 50px;
  `)}
`;

const LinkButton = styled.button`
  display: flex;
  width: 300px;
  height: 40px;
  padding: 4px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 20px;
  background-color: ${({ theme }) => theme.newColors.grey1};
  box-shadow:
    0px 3px 5px 0px rgba(0, 0, 0, 0.15),
    1px 1px 2px 0px rgba(244, 245, 249, 0.4) inset;

  color: ${({ theme }) => theme.newColors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  &:hover {
    background-color: ${({ theme }) => theme.newColors.primary};
  }

  margin: auto;

  ${({ theme }) =>
    theme.media.mobile(`
      width: auto;
      word-break: keep-all;
  `)}
`;
