import React from 'react';
import styled from 'styled-components';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  width?: string;
  isHoveredColor?: boolean;
}

const ConfirmButton = ({ text, isHoveredColor = true, width = '100%', ...props }: IProps) => {
  return (
    <Button $isHoveredColor={isHoveredColor} $width={width} {...props}>
      {text}
    </Button>
  );
};

export default ConfirmButton;

const Button = styled.button<{ $isHoveredColor?: boolean; $width: string }>`
  width: ${({ $width }) => $width};
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.newColors.grey1};
  box-shadow:
    1px 1px 2px 0px rgba(244, 245, 249, 0.4) inset,
    0px 3px 5px 0px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.newColors.white};

  font-size: 16px;
  font-variation-settings: 'wght' 500;
  line-height: 20px;
  letter-spacing: -0.32px;

  ${({ theme, $isHoveredColor }) =>
    $isHoveredColor &&
    `&:hover {
      background-color: ${theme.newColors.primary};
    }`}

  &:disabled {
    background-color: ${({ theme }) => theme.newColors.dark3};
    color: ${({ theme }) => theme.newColors.grey1};

    box-shadow:
      0px 3px 5px 0px rgba(0, 0, 0, 0.15),
      1px 1px 1px 0px rgba(155, 161, 178, 0.3) inset;
    cursor: not-allowed;
  }
`;
