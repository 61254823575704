import { ChartData } from '@static/chartData';
import hexToRGBA from '@utils/hexToRGBA';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  title: string;
  groupIds: { [key: string]: boolean };
};

const ChartItem = ({ children, title, groupIds }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <Legend>
          {Object.keys(groupIds).map((groupId) => {
            return (
              <C.Item key={groupId}>
                <C.Color $color={ChartData[parseInt(groupId)].borderColor} />
                <C.Name>{t(ChartData[parseInt(groupId)].title)}</C.Name>
              </C.Item>
            );
          })}
        </Legend>
        <span>{t('common.unit')} : tonCO2eq</span>
      </Header>
      <ChartContainer>{children}</ChartContainer>
    </Container>
  );
};

export default ChartItem;

const C = {
  Item: styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
  Color: styled.div<{ $color: string }>`
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: ${(props) => props.$color};
  `,
  Name: styled.div`
    font-size: 16px;
    font-variation-settings: 'wght' 400;
    line-height: 20px;
    letter-spacing: -0.32px;
  `,
};

const Header = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 1px solid ${({ theme }) => theme.newColors.dark3};
  padding-bottom: 20px;

  span {
    color: ${({ theme }) => theme.newColors.grey2};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;

    margin-left: 20px;
  }

  ${({ theme }) =>
    theme.media.mobile(`
      gap: 20px;
      flex-direction: column;
      align-items: start;

      span {
        margin-left: 0px;
      }
  `)}
`;

const Legend = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 20px;
  margin-left: auto;

  ${({ theme }) =>
    theme.media.mobile(`
      margin-left: 0px;
  `)}
`;

const ChartContainer = styled.div`
  flex: 1 1 0;
  position: relative;
`;

const Title = styled.div`
  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;
`;

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};

  padding: 20px;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;
