import styled from 'styled-components';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/chevron_left_icon.svg';
import { ReactComponent as ChevronRightIcon } from '@assets/icons/chevron_right_icon.svg';
import { ReactComponent as PlayIcon } from '@assets/icons/panel/play_icon.svg';
import { ReactComponent as PauseIcon } from '@assets/icons/panel/pause_icon.svg';
import MapCommonButton from '@components/common/MapCommonButton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import RangeSlider from '@components/common/RangeSlider';
import useInterval from '@hooks/useInterval';
import useMapStore from 'stores/mapStore';
import { newColors } from '@styles/theme';
import hexToRGBA from '@utils/hexToRGBA';
import { TWidthType } from '@hooks/useWidthType';
import { MapRef } from 'react-map-gl';

type TProps = {
  map: MapRef;
  widthType: TWidthType;
};

const TimelapsePanel = ({ map, widthType }: TProps) => {
  const { selectDataCondition, activeYearMonth, updateTimelapseMonth } = useMapStore();
  const [isPanelOpen, setIsPanelOpen] = useState(widthType !== 'mobile' ? true : false);
  const [isPlaying, setIsPlaying] = useState(false);
  const totalMonth = useMemo(() => {
    return selectDataCondition.endDate.diff(selectDataCondition.startDate, 'month') || 0;
  }, [selectDataCondition]);

  const handlePlayButtonClick = () => {
    setIsPlaying((prev) => !prev);
  };

  const handlePreviousButtonClick = () => {
    const prev = activeYearMonth.diff(selectDataCondition.startDate, 'month') || 0;
    updateTimelapseMonth(prev === 0 ? 0 : prev - 1);
  };

  const handleForwardButtonClick = () => {
    const prev = activeYearMonth.diff(selectDataCondition.startDate, 'month') || 0;
    updateTimelapseMonth(prev === totalMonth ? totalMonth : prev + 1);
  };

  const handleChange = (value: number) => {
    updateTimelapseMonth(value);
  };

  const handleButtonClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsPanelOpen((prev) => !prev);
  }, []);

  useInterval(
    () => {
      const prev = activeYearMonth.diff(selectDataCondition.startDate, 'month') || 0;
      updateTimelapseMonth(prev === totalMonth ? 0 : prev + 1);
    },
    isPlaying ? 1000 : null,
  );

  useEffect(() => {
    const target = ['div.mapboxgl-ctrl-bottom-left', 'div.mapboxgl-ctrl-bottom-right', 'div.mobile-legend'];
    const updateTransform = (selector: string, isMobile: boolean) => {
      const element = map.getContainer().querySelector(selector) as HTMLElement;
      if (element) {
        element.style.transform = isMobile ? `translateY(${isPanelOpen ? '-120px' : '-40px'})` : 'none';
      }
    };

    target.map((item) => updateTransform(item, widthType === 'mobile'));
  }, [isPanelOpen, map, widthType]);

  return (
    <Container $isPanelOpen={isPanelOpen}>
      <PanelOpenControl $isPanelOpen={isPanelOpen} onClick={handleButtonClick}>
        <ChevronRightIcon />
      </PanelOpenControl>
      <ButtonContainer>
        <MapCommonButton
          icon={<ChevronLeftIcon />}
          type={widthType === 'mobile' ? 'default' : 'small'}
          isHover={true}
          onClick={handlePreviousButtonClick}
        />
        <MapCommonButton
          icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
          type={widthType === 'mobile' ? 'default' : 'small'}
          isHover={true}
          onClick={handlePlayButtonClick}
        />
        <MapCommonButton
          icon={<ChevronRightIcon />}
          type={widthType === 'mobile' ? 'default' : 'small'}
          isHover={true}
          onClick={handleForwardButtonClick}
        />
      </ButtonContainer>
      <RangeSlider
        min={0}
        max={totalMonth}
        value={activeYearMonth.diff(selectDataCondition.startDate, 'month') || 0}
        filledColor={newColors.primary}
        handleChange={handleChange}
      />
      <DateSpan>{activeYearMonth.format('YYYY.MM')}</DateSpan>
    </Container>
  );
};

export default TimelapsePanel;

const Container = styled.div<{ $isPanelOpen: boolean }>`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 645px;
  padding: 15px;
  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark1, 0.5)};
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 3;

  ${({ theme }) =>
    theme.media.tablet(`
      bottom: 0px;
      width: 100%;
      border-radius: 5px 5px 0px 0px;
  `)}

  ${({ theme, $isPanelOpen }) =>
    theme.media.mobile(`
      bottom: 0px;
      left: initial;
      width: 100%;
      border-radius: 5px 5px 0px 0px;

      flex-direction: column;
      transform: translateY(${$isPanelOpen ? 0 : 'calc(100% - 40px)'});
  `)};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  ${({ theme }) =>
    theme.media.mobile(`
      order: 1;
  `)};
`;

const DateSpan = styled.span`
  width: 55px;
  min-width: 55px;
  font-size: 14px;
  font-variation-settings: 'wght' 500;
  letter-spacing: -0.28px;
  text-align: center;

  ${({ theme }) =>
    theme.media.mobile(`
      position: absolute;
      bottom: 25px;
      right: 20px;
  `)};
`;

const PanelOpenControl = styled.button<{ $isPanelOpen: boolean }>`
  display: none;
  padding: 0 15px;

  ${({ theme, $isPanelOpen }) =>
    theme.media.mobile(`
      display: block;
      svg {
        transform: ${`rotate(${$isPanelOpen ? '90' : '270'}deg)`}
      }
  `)};
`;
