import React from 'react';
import useWidthType from '@hooks/useWidthType';
import NetworkMap from './NetworkMap';

const Network = () => {
  const widthType = useWidthType();

  return <>{widthType && <NetworkMap widthType={widthType} />}</>;
};

export default Network;
