import { styled } from 'styled-components';
import { useState } from 'react';
import { ReactComponent as LogoBlackIcon } from '@assets/icons/header/logo_black_icon.svg';
import { ReactComponent as LogoWhiteIcon } from '@assets/icons/header/logo_white_icon.svg';
import { ReactComponent as MenuMobileIcon } from '@assets/icons/header/menu_mobile_icon.svg';
import BasicModal from '@components/common/BasicModal';
import MobileMenuModal from './MobileMenuModal';
import { Link } from 'react-router-dom';
import { colors } from '@styles/theme';

const MobileHeader = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const handleMenuOpen = () => {
    setIsMenuOpened(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpened(false);
  };

  return (
    <>
      <BasicModal portalID="#portal" isOpen={isMenuOpened} onRequestClose={handleMenuClose} isCloseWhenAroundClick={false} dimBg={false}>
        <MobileMenuModal handleMenuClose={handleMenuClose} />
      </BasicModal>
      <Container>
        <Link to="/">
          <LogoWhiteIcon />
        </Link>
        <HeaderRight>
          <button onClick={handleMenuOpen}>
            <MenuMobileIcon stroke={colors.text} />
          </button>
        </HeaderRight>
      </Container>
    </>
  );
};

export default MobileHeader;

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 10px 0 20px;
  background-color: ${({ theme }) => theme.newColors.dark1};
  z-index: 3;
`;

const HeaderRight = styled.div`
  display: flex;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
  }
`;
